<template>
  <b-container class="h-100 d-md-flex flex-column justify-content-between p-0 landingPage" fluid
    :class="currentLocale == 'en' ? 'eng' : 'arabic'">
    <!-- Header -->
    <section class="d-flex flex-wrap justify-content-between"
      style="background: #FFFCF4;;width:100%;height:50px;">
      <b-row class="w-100 p-0 m-0">
        <b-col md="12" class="landingHeader p-0 ">
          <b-navbar toggleable="lg" class="w-100 p-0">
            <b-navbar-brand href="#" class="p-3">
              <img :src="base + '/media/landing/logo.png'" alt="">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav class="p-3">

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-item href="#" v-if="language=='Eng'" class="navLinks">Home</b-nav-item>
                <b-nav-item href="#" v-else class="navLinks">في البيت</b-nav-item>

                <b-nav-item href="#"  v-if="language=='Eng'" class="navLinks">FAQs</b-nav-item>
                <b-nav-item href="#" v-else class="navLinks">اللعنة</b-nav-item>
                <b-nav-item href="#"  v-if="language=='Eng'" class="navLinks">Join Us</b-nav-item>
                <b-nav-item href="#" v-else class="navLinks">انضم إلينا</b-nav-item>
                <b-nav-item-dropdown right class="language mt-2">
                  <template #button-content>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="mr-3">
                      <g clip-path="url(#clip0_4904_1344)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.99996 3.33366C8.23185 3.33366 6.53616 4.03604 5.28591 5.28628C4.03567 6.53652 3.33329 8.23222 3.33329 10.0003C3.33329 11.7684 4.03567 13.4641 5.28591 14.7144C6.53616 15.9646 8.23185 16.667 9.99996 16.667C11.7681 16.667 13.4638 15.9646 14.714 14.7144C15.9642 13.4641 16.6666 11.7684 16.6666 10.0003C16.6666 8.23222 15.9642 6.53652 14.714 5.28628C13.4638 4.03604 11.7681 3.33366 9.99996 3.33366ZM1.66663 10.0003C1.66663 5.39783 5.39746 1.66699 9.99996 1.66699C14.6025 1.66699 18.3333 5.39783 18.3333 10.0003C18.3333 14.6028 14.6025 18.3337 9.99996 18.3337C5.39746 18.3337 1.66663 14.6028 1.66663 10.0003Z"
                          fill="#0F0090" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.86327 4.98033C8.41327 6.22533 8.11827 8.00033 8.11827 10.0003C8.11827 12.0003 8.41327 13.7753 8.86327 15.0203C9.08993 15.6453 9.34077 16.0937 9.5766 16.3687C9.81493 16.6478 9.96077 16.667 9.99993 16.667C10.0391 16.667 10.1849 16.6478 10.4233 16.3687C10.6591 16.0937 10.9099 15.6462 11.1358 15.0203C11.5866 13.7753 11.8816 12.0003 11.8816 10.0003C11.8816 8.00033 11.5866 6.22533 11.1358 4.98033C10.9099 4.35533 10.6591 3.90699 10.4233 3.63116C10.1849 3.35366 10.0391 3.33366 9.99993 3.33366C9.96077 3.33366 9.81493 3.35283 9.5766 3.63116C9.33993 3.90699 9.08993 4.35449 8.86327 4.98116V4.98033ZM8.30993 2.54783C8.7216 2.06699 9.2891 1.66699 9.99993 1.66699C10.7108 1.66699 11.2783 2.06699 11.6899 2.54783C12.1033 3.03116 12.4383 3.68199 12.7033 4.41366C13.2358 5.88283 13.5483 7.85866 13.5483 10.0003C13.5483 12.142 13.2358 14.117 12.7033 15.587C12.4383 16.3187 12.1033 16.9695 11.6899 17.4528C11.2783 17.9328 10.7108 18.3337 9.99993 18.3337C9.2891 18.3337 8.7216 17.9337 8.30993 17.4528C7.8966 16.9695 7.5616 16.3195 7.2966 15.587C6.7641 14.1178 6.4516 12.142 6.4516 10.0003C6.4516 7.85866 6.7641 5.88366 7.2966 4.41366C7.5616 3.68199 7.8966 3.03116 8.30993 2.54783Z"
                          fill="#0F0090" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.14661 7.59993C2.14661 7.37892 2.2344 7.16696 2.39068 7.01068C2.54696 6.8544 2.75893 6.7666 2.97994 6.7666H16.9899C17.211 6.7666 17.4229 6.8544 17.5792 7.01068C17.7355 7.16696 17.8233 7.37892 17.8233 7.59993C17.8233 7.82095 17.7355 8.03291 17.5792 8.18919C17.4229 8.34547 17.211 8.43327 16.9899 8.43327H2.97994C2.75893 8.43327 2.54696 8.34547 2.39068 8.18919C2.2344 8.03291 2.14661 7.82095 2.14661 7.59993ZM2.14661 12.3999C2.14661 12.1789 2.2344 11.967 2.39068 11.8107C2.54696 11.6544 2.75893 11.5666 2.97994 11.5666H16.9899C17.211 11.5666 17.4229 11.6544 17.5792 11.8107C17.7355 11.967 17.8233 12.1789 17.8233 12.3999C17.8233 12.6209 17.7355 12.8329 17.5792 12.9892C17.4229 13.1455 17.211 13.2333 16.9899 13.2333H2.97994C2.75893 13.2333 2.54696 13.1455 2.39068 12.9892C2.2344 12.8329 2.14661 12.6209 2.14661 12.3999Z"
                          fill="#0F0090" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4904_1344">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span style="color:#0F0090">{{ language }}</span>
                  </template>
                  <b-dropdown-item href="#" @click="handleClick('Eng')">Eng</b-dropdown-item>
                  <b-dropdown-item href="#" @click="handleClick('AR')">AR</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
          <!-- <ul class="p-0">
          <li><a href="/">Home</a></li>
          <li><a href="/">FAQs</a></li>
          <li><a href="/join?country">Join Us</a></li>
          <li class="d-flex align-items-center">
            <div class="language">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4904_1344)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99996 3.33366C8.23185 3.33366 6.53616 4.03604 5.28591 5.28628C4.03567 6.53652 3.33329 8.23222 3.33329 10.0003C3.33329 11.7684 4.03567 13.4641 5.28591 14.7144C6.53616 15.9646 8.23185 16.667 9.99996 16.667C11.7681 16.667 13.4638 15.9646 14.714 14.7144C15.9642 13.4641 16.6666 11.7684 16.6666 10.0003C16.6666 8.23222 15.9642 6.53652 14.714 5.28628C13.4638 4.03604 11.7681 3.33366 9.99996 3.33366ZM1.66663 10.0003C1.66663 5.39783 5.39746 1.66699 9.99996 1.66699C14.6025 1.66699 18.3333 5.39783 18.3333 10.0003C18.3333 14.6028 14.6025 18.3337 9.99996 18.3337C5.39746 18.3337 1.66663 14.6028 1.66663 10.0003Z" fill="#0F0090"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.86327 4.98033C8.41327 6.22533 8.11827 8.00033 8.11827 10.0003C8.11827 12.0003 8.41327 13.7753 8.86327 15.0203C9.08993 15.6453 9.34077 16.0937 9.5766 16.3687C9.81493 16.6478 9.96077 16.667 9.99993 16.667C10.0391 16.667 10.1849 16.6478 10.4233 16.3687C10.6591 16.0937 10.9099 15.6462 11.1358 15.0203C11.5866 13.7753 11.8816 12.0003 11.8816 10.0003C11.8816 8.00033 11.5866 6.22533 11.1358 4.98033C10.9099 4.35533 10.6591 3.90699 10.4233 3.63116C10.1849 3.35366 10.0391 3.33366 9.99993 3.33366C9.96077 3.33366 9.81493 3.35283 9.5766 3.63116C9.33993 3.90699 9.08993 4.35449 8.86327 4.98116V4.98033ZM8.30993 2.54783C8.7216 2.06699 9.2891 1.66699 9.99993 1.66699C10.7108 1.66699 11.2783 2.06699 11.6899 2.54783C12.1033 3.03116 12.4383 3.68199 12.7033 4.41366C13.2358 5.88283 13.5483 7.85866 13.5483 10.0003C13.5483 12.142 13.2358 14.117 12.7033 15.587C12.4383 16.3187 12.1033 16.9695 11.6899 17.4528C11.2783 17.9328 10.7108 18.3337 9.99993 18.3337C9.2891 18.3337 8.7216 17.9337 8.30993 17.4528C7.8966 16.9695 7.5616 16.3195 7.2966 15.587C6.7641 14.1178 6.4516 12.142 6.4516 10.0003C6.4516 7.85866 6.7641 5.88366 7.2966 4.41366C7.5616 3.68199 7.8966 3.03116 8.30993 2.54783Z" fill="#0F0090"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14661 7.59993C2.14661 7.37892 2.2344 7.16696 2.39068 7.01068C2.54696 6.8544 2.75893 6.7666 2.97994 6.7666H16.9899C17.211 6.7666 17.4229 6.8544 17.5792 7.01068C17.7355 7.16696 17.8233 7.37892 17.8233 7.59993C17.8233 7.82095 17.7355 8.03291 17.5792 8.18919C17.4229 8.34547 17.211 8.43327 16.9899 8.43327H2.97994C2.75893 8.43327 2.54696 8.34547 2.39068 8.18919C2.2344 8.03291 2.14661 7.82095 2.14661 7.59993ZM2.14661 12.3999C2.14661 12.1789 2.2344 11.967 2.39068 11.8107C2.54696 11.6544 2.75893 11.5666 2.97994 11.5666H16.9899C17.211 11.5666 17.4229 11.6544 17.5792 11.8107C17.7355 11.967 17.8233 12.1789 17.8233 12.3999C17.8233 12.6209 17.7355 12.8329 17.5792 12.9892C17.4229 13.1455 17.211 13.2333 16.9899 13.2333H2.97994C2.75893 13.2333 2.54696 13.1455 2.39068 12.9892C2.2344 12.8329 2.14661 12.6209 2.14661 12.3999Z" fill="#0F0090"/>
                </g>
                <defs>
                <clipPath id="clip0_4904_1344">
                <rect width="20" height="20" fill="white"/>
              </clipPath> 
            </defs>
          </svg>
        </div>
              <select class="landingSelect">
                <option value="eng">Eng</option>
                <option value="ar">AR</option>
              </select>
          </li>
        </ul> -->
        </b-col>
      </b-row>

    </section>
    <section class="w-100 ">
      <b-row class="m-0 w-100 h-100 p-0 landingmain">
        <b-col md="4" class="p-0 order-md-1 d-none d-md-block">
          <div class="mt-4">
            <img :src="base + '/media/landing/mobile1.png'" alt="" style="max-width: 100%;max-height: 570px;">
          </div>
        </b-col>
        <b-col md="4" class="p-3 order-md-2 order-2 h-100 d-flex flex-column ">
          <div class="w-100  d-flex flex-column justify-content-center align-items-start mt-5 "  v-if="language == 'Eng'">
            <div class="logo">
              <img :src="base + '/media/landing/Layer_1-2.svg'" alt="" class="w-100 " style="max-width: 100%">
            </div>
            <h2 class="mt-4">
              Is here
            </h2>
            <p class="mt-2">Become a social media influencer and monetize your influence with Moon! Advertise your
              favorite brands using exclusive coupons and earn a percentage of the sales. Simply register to get started!
            </p>
            <a class="registerNow mt-5"
            :href="'https://app.adjust.com/16bak28b'+ queries">
              Register Now</a>
          </div>
          <div v-else class="w-100  d-flex flex-column justify-content-center align-items-start mt-5">
            <div class="logo">
              <img :src="base + '/media/landing/Layer_1-2.svg'" alt="" class="w-100 " style="max-width: 100%">
            </div>
            <h2 class="mt-4">
              هنا
            </h2>
            <p class="mt-2">كن مؤثرًا على وسائل التواصل الاجتماعي واستثمر تأثيرك مع Moon! أعلن عن علاماتك التجارية المفضلة باستخدام كوبونات حصرية واكسب نسبة من المبيعات. ما عليك سوى التسجيل للبدء!
            </p>
            <a class="registerNow mt-5"
              :href="'https://app.adjust.com/16bak28b'+ queries">
              سجل نوفا</a>
          </div>
          <div class="d-flex mt-5 align-items-center w-100">
            <b-row>
              <b-col lg="5" cols="6">
                <a :href="'https://app.adjust.com/16bak28b'+ queries"
                  class="mt-2 ml-2 w-100">
                  <img :src="base + '/media/landing/appstore.png'" alt="" class="w-100">
                </a>
              </b-col>
              <b-col lg="5" cols="6">

                <a :href="'https://app.adjust.com/16bak28b'+ queries"
                  class="ml-2 mt-2 w-100">
                  <img :src="base + '/media/landing/playstore.png'" alt="" class="w-100">
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="4" class="mt-4 order-md-3 order-1 p-0 d-flex justify-content-end mobile2">
          <div>
            <img :src="base + '/media/landing/mobile2.png'" alt="" style="max-width: 100%;max-height: 570px;">
          </div>
        </b-col>
      </b-row>
    </section>
    <section class="landingFooter d-flex justify-content-between align-items-center p-2">
      <div class="h-100 d-flex align-items-center">
        @ 2023 Moon
      </div>
      <div class="d-flex mr-4">
        <a class="ml-3" href="https://www.instagram.com/influencer.moon/">
          <img :src="base + '/media/landing/instagram.svg'" alt="">
        </a>
      </div>
    </section>
  </b-container>
</template>

<script>
import { SET_LOCALE } from "@/core/services/store/auth.module";
import { createLogger } from 'vuex';

export default {
  name: "MoonLandingPage",
  created() {
    const countryParam = this.$route.query.country;
    const langParam = this.$route.query.lang;
    if (countryParam) {
      // if comes from referal link redirect to referal step.
      this.country = countryParam;
    }
    if (langParam && (langParam == "ar" || langParam == "en")) {
      // if comes language param then set currentLocale according to them.
      this.changeLocale(langParam);
    }
  },
  components: {},
  data() {
    return {
      country: "",
      base: window.location.origin,
      language: localStorage.getItem('locale')?(localStorage.getItem('locale')=='en'?'eng':'ar'):'Eng',
      queries:'?'
    };
  },
  mounted(){
    let arr = Object.keys(this.$route.query)
    let arr1 = Object.keys(this.$route.query)
    let last = arr.pop()
    let tempObj = {}
    let tempObjLast = {}
    arr.map((item)=>{
      if(item == 'utm_source'){
        tempObj[item] ='campaign'
      }else if(item ==  'utm_medium'){
        tempObj[item]= 'adgroup'
      }else if(item == 'utm_campaign'){
        tempObj[item] ='creative'
      }
      this.queries+=`${tempObj[item]}=${this.$route.query[item]}&`
    })
      if(last == 'utm_source'){
        tempObjLast[last] = 'campaign'
      }else if(last ==  'utm_medium'){
        tempObjLast[last] = 'adgroup'
      }else if(last == 'utm_campaign'){
        tempObjLast[last] ='creative'
      }
    if(last){
      this.queries+=`${tempObjLast[last]}=${this.$route.query[last]}`
    }
    if(arr1.length > 0){
      this.queries+=`&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.itcan.moon&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fmoon-influencer-marketing-app%2Fid1670279602`;
    }else{
      this.queries+=`redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.itcan.moon&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fmoon-influencer-marketing-app%2Fid1670279602`;
    }
    console.log(this.queries)
  },
  methods: {
    handleClick(name) {
      this.language = name
      localStorage.setItem(name == 'Eng'?'en':'ar')
    },
    changeLocale(e) {
      this.$i18n.locale = e;
      localStorage.setItem("locale", e);
      this.$store.dispatch(SET_LOCALE, e);
    },
  },
  computed: {
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },
};
</script>

<style lang="scss" scoped>
.navLinks{
  margin-top:5px;
}
.landingmain {
  background: #FFFCF4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landingHeader .navLinks {
  padding: 7px;
  cursor: pointer;
  margin-left: 5px
}

.landingHeader {
  z-index: 100;
}

.landingmain h2 {
  font-size: 55px;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #0F0090;
}

.landingmain p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.0172em;
  text-align: left;
  color: #0F0090;
}

.landingHeader li a {
  color: #0F0090;
}

.landingHeader li:nth-child(3) a {
  color: white !important;
}

.landingHeader li:nth-child(3) {
  background: #0F0090;
  color: white !important;
  border-radius: 32px;
  width: 105.06px;
  text-align: center;
  font-weight: bold;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerNow {
  width: 182px;
  height: 55px;
  border-radius: 32px;
  background: #0F0090;
  box-shadow: 0px 2px 0px 0px #413D4533;
  color: white;
  border: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width:500px) {
  .registerNow {
    width: 100% !important;
  }
}
@media screen and (max-width:750px) {
  
  .mobile2{
    background: #F5F2EA;
  }
}

.landingFooter {
  background: #0F0090;
  color: white;
  height: 70px;
}

.landingPage section:nth-child(2) {
  min-height: calc(100% - 120px);
}

.language {
  border: 1px solid #0F0090;
  border-radius: 60px;
  padding: 5px;
  margin-left: 5px;
  width:105px;
  height: 40px;
  display: flex;
  align-items: center;
}

.landingHeader .nav-link,
.landingHeader .nav-link:hover {
  color: #0F0090;
  font-weight: 600;
}

</style>
<style>
.landingPage .navbar-nav .dropdown-menu{
  position: absolute !important;
  left: 0px;
}
.landingPage .navbar-collapse.show {
  background: #FFFCF4;

}
</style>
